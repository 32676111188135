<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.64153 12.9589C4.04708 12.0034 4.99395 11.3333 6.09733 11.3333H10.0973C11.2007 11.3333 12.1476 12.0035 12.5531 12.9589M10.764 6.33331C10.764 7.80607 9.57009 8.99998 8.09733 8.99998C6.62457 8.99998 5.43066 7.80607 5.43066 6.33331C5.43066 4.86055 6.62457 3.66665 8.09733 3.66665C9.57009 3.66665 10.764 4.86055 10.764 6.33331ZM14.764 7.99998C14.764 11.6819 11.7792 14.6666 8.09733 14.6666C4.41543 14.6666 1.43066 11.6819 1.43066 7.99998C1.43066 4.31808 4.41543 1.33331 8.09733 1.33331C11.7792 1.33331 14.764 4.31808 14.764 7.99998Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconUserCircle'
}
</script>
